/* istanbul ignore file */

import {
  AuthProvider,
  ErrorBoundaryContextProvider,
  SettingsProvider,
  SigninSilentOidc,
  withErrorBoundary
} from "adviesbox-shared";
import React, { ReactElement } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import logo from "./assets/adviesbox-logo.svg";
import PortalApp from "./portal-app/PortalApp";
import "./styles/style.scss";
import UserDetailsProvider from "./user-details/user-details-provider";
const App: React.FC = () => {
  return (
    <ErrorBoundaryContextProvider>
      <BrowserRouter>
        <SettingsProvider loadingLogo={logo}>
          <Switch>
            <Route path="/silent-redirect" component={withErrorBoundary(SigninSilentOidc)} />
            <Route
              render={(): ReactElement => (
                <AuthProvider>
                  <UserDetailsProvider>
                    <PortalApp />
                  </UserDetailsProvider>
                </AuthProvider>
              )}
            />
          </Switch>
        </SettingsProvider>
      </BrowserRouter>
    </ErrorBoundaryContextProvider>
  );
};

export default App;
