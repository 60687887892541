/* istanbul ignore file */

import {
  AuthContext,
  getNaamAdviseurInformeel,
  HttpErrorPage,
  NotificatiesProvider,
  PageLoading,
  useRequestInit
} from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import useAbortableFetch from "use-abortable-fetch";
import CurrentVestigingContext from "../current-vestiging/current-vestiging-context";
import CurrentVestigingProvider from "../current-vestiging/current-vestiging-provider";
import {
  Medewerker,
  Organisatie,
  OrganisatiesOutput,
  Productonderdeel,
  ProductonderdelenOutput,
  RechtenOutPut,
  Vestiging,
  VestigingenOutput
} from "../.generated/licenties/licentiestypes";
import { Title } from "../shared/components/title/title";
import { StartSchermProps } from "./infra";
import { mapDl2Ui } from "./infra/startscherm-mapper";
import { StartschermBody } from "./startscherm-body/startscherm-body";
import TopNavbar from "../topnavbar/TopNavbar";
import { NieuwsItem, NieuwsItemOutput } from "../.generated/nieuws/nieuwstypes";

export const StartschermBinnenCurrentVestiging = ({
  medewerkerId,
  productId,
  organisatieId,
  preSignedToken,
  renteNieuwsLijst,
  isISWGebruiker
}: StartSchermProps): ReactElement => {
  const { signout } = useContext(AuthContext);
  const { settings, requestInit } = useRequestInit();
  const { vestiging } = useContext(CurrentVestigingContext);

  const vestigingen = useAbortableFetch<VestigingenOutput>(
    `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Vestigingen`,
    requestInit
  );
  const productOnderdelen = useAbortableFetch<ProductonderdelenOutput>(
    `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Producten/${productId}/Productonderdelen`,
    requestInit
  );
  const productonderdelenProduct = useAbortableFetch<ProductonderdelenOutput>(
    `${settings.licentiesOrigin}/Producten/${productId}/Productonderdelen`,
    requestInit
  );
  const organisatie = useAbortableFetch<OrganisatiesOutput>(
    `${settings.licentiesOrigin}/Organisaties/${organisatieId}`,
    requestInit
  );
  const rechten = useAbortableFetch<RechtenOutPut>(
    `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Rechten`,
    requestInit
  );

  const gebruiker = useAbortableFetch<Medewerker>(
    `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}`,
    requestInit
  );

  const alleActieveVestigingen = useAbortableFetch<VestigingenOutput>(
    `${settings.licentiesOrigin}/Organisatie/${organisatieId}/Vestigingen`,
    requestInit
  );

  const nieuws = useAbortableFetch<NieuwsItemOutput>(
    `${settings.nieuwsOrigin}/Nieuws/huidig`,
    requestInit
  );
  if (
    vestigingen.error ||
    productOnderdelen.error ||
    organisatie.error ||
    rechten.error ||
    gebruiker.error ||
    alleActieveVestigingen.error ||
    productonderdelenProduct.error ||
    nieuws.error
  ) {
    throw Error(
      "Helaas er is een probleem opgetreden bij het ophalen van gegevens neem contact op met"
    );
  }

  if (
    vestigingen.loading ||
    productOnderdelen.loading ||
    organisatie.loading ||
    rechten.loading ||
    gebruiker.loading ||
    alleActieveVestigingen.loading ||
    productonderdelenProduct.loading ||
    !vestigingen.data ||
    !productOnderdelen.data ||
    !organisatie.data ||
    !rechten.data ||
    !gebruiker.data ||
    !alleActieveVestigingen.data ||
    !productonderdelenProduct.data ||
    !nieuws.data
  ) {
    return <PageLoading />;
  }

  const vestigingenData = mapDl2Ui<{ [key: string]: Vestiging }>(
    vestigingen.data,
    "vestigingen",
    false
  );
  const productOnderdelenData = mapDl2Ui<Productonderdeel[]>(
    productOnderdelen.data,
    "productOnderdelen",
    true
  );
  const organisatieData = mapDl2Ui<Organisatie>(
    organisatie.data,
    "organisaties",
    false,
    organisatieId
  );
  const rechtenData = mapDl2Ui<string[]>(rechten.data, "rechten", true);
  const gebruikerData = mapDl2Ui<Medewerker>(
    gebruiker.data,
    "medewerkers",
    false,
    medewerkerId
  );
  const alleOrganisatieActieveVestigingenData = mapDl2Ui<{
    [key: string]: Vestiging;
  }>(alleActieveVestigingen.data, "vestigingen", false);
  const productonderdelenProductData = mapDl2Ui<Productonderdeel[]>(
    productonderdelenProduct.data,
    "productOnderdelen",
    true
  );

  const nieuwsData = mapDl2Ui<NieuwsItem[]>(nieuws.data, "nieuwsItems", true);

  if (
    !vestigingenData ||
    !productOnderdelenData ||
    !organisatieData ||
    !rechtenData ||
    !gebruikerData ||
    !alleActieveVestigingen ||
    !productonderdelenProductData ||
    !nieuwsData
  ) {
    return (
      <HttpErrorPage
        status={403}
        subMessage="Je hebt onvoldoende rechten om de applicatie te gebruiken. Neem contact op met de beheerder van je organisatie."
        buttonConfig={{
          buttonFn: signout,
          buttonText: "Uitloggen"
        }}
      />
    );
  }

  const medewerkerActieveVestigingenIds: string[] = Object.keys(
    vestigingenData
  ).filter((v) =>
    Object.keys(alleOrganisatieActieveVestigingenData).includes(v)
  );
  const medewerkerActieveVestigingenData = medewerkerActieveVestigingenIds.map(
    (id) => [id, alleOrganisatieActieveVestigingenData[id]]
  );
  /* istanbul ignore if */
  if (process.env.NODE_ENV === "development") {
    /* eslint-disable-next-line no-console */
    console.log(
      `Retrieved following data \n\n`,
      `organisatie => \n`,
      organisatieData,
      `\n\n`,
      `Alle medewerkers vestigingen (Actief & non-actief) => \n`,
      vestigingenData,
      `\n\n`,
      `Actieve medewerkers vestigingen => \n`,
      medewerkerActieveVestigingenData,
      `\n\n`,
      `alle actieve vestigingen van de organisatie => \n`,
      alleOrganisatieActieveVestigingenData,
      `\n\n`,
      `productOnderdelen => \n`,
      productOnderdelenData,
      `\n\n`,
      `medewerkerId =>`,
      medewerkerId,
      `\n`,
      `productId =>`,
      productId,
      `\n`,
      `rechten =>`,
      rechtenData,
      `\n`,
      `gebruiker =>`,
      gebruikerData,
      `\n`,
      `settings =>`,
      settings,
      "\n",
      "productonderdelen product =>",
      productonderdelenProductData
    );
  }

  return (
    <NotificatiesProvider
      reloadTimeInSeconds={60}
      medewerkerId={medewerkerId}
      vestigingId={vestiging.id}
      sourceApp={"ADV"}
    >
      <Title appName="Portaal" altTitle="Index" />
      <TopNavbar
        organisatie={organisatieData}
        vestigingen={Object.fromEntries(medewerkerActieveVestigingenData)}
        dark={true}
      />
      <StartschermBody
        productOnderdelen={productOnderdelenData}
        rechten={rechtenData}
        gebruikersNaam={getNaamAdviseurInformeel(gebruikerData)}
        renteNieuwsLijst={renteNieuwsLijst ?? []}
        preSignedToken={preSignedToken ?? null}
        isISWGebruiker={isISWGebruiker}
        productonderdelenProduct={productonderdelenProductData}
        nieuws={nieuwsData}
      />
    </NotificatiesProvider>
  );
};

export const Startscherm = ({
  medewerkerId,
  productId,
  organisatieId,
  preSignedToken,
  renteNieuwsLijst,
  isISWGebruiker
}: StartSchermProps): ReactElement => {
  return (
    <CurrentVestigingProvider>
      <StartschermBinnenCurrentVestiging
        medewerkerId={medewerkerId}
        productId={productId}
        organisatieId={organisatieId}
        isISWGebruiker={isISWGebruiker}
        preSignedToken={preSignedToken}
        renteNieuwsLijst={renteNieuwsLijst}
      />
    </CurrentVestigingProvider>
  );
};
