import {
  AuthContext,
  ErrorPage,
  getCookie,
  getOTAP,
  hasRoleIswBeheerder,
  Icon,
  Notificaties,
  NotificatiesContext,
  setCookie,
  SettingsContext,
  SupportRemoteAssistanceModal,
  SupportTicketModalAjax,
} from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import CurrentVestigingContext from "../current-vestiging/current-vestiging-context";
import { TopNavbarProps } from "./infra";
import "./TopNavbar.scss";

function isLocal(): boolean {
  return window.location.hostname.includes("localhost");
}

type SupportMenuProps = {
  showMeldingMaken: () => void;
  showRemoteAssistance: () => void;
};

const SupportMenu = (props: SupportMenuProps): ReactElement => {
  return (
    <Dropdown className="navigation__toggle">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-basic-support"
        data-testid="btn-support"
      >
        <span>
          <Icon
            name="questionmark"
            iconSize="xl"
            className="navigation__icon"
            alt="Question"
          />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu data-testid="support-menu">
        <Dropdown.Item
          onClick={
            /* istanbul ignore next */ () => {
              props.showMeldingMaken();
            }
          }
          id="menu-optie-melding-maken"
        >
          Melding maken
        </Dropdown.Item>
        <Dropdown.Item
          onClick={
            /* istanbul ignore next */ () => {
              props.showRemoteAssistance();
            }
          }
          id="menu-optie-hulp-op-afstand"
        >
          Hulp op afstand
        </Dropdown.Item>
        <Dropdown.Item
          onClick={
            /* istanbul ignore next*/
            () => {
              window.open(
                "https://support.adviesbox.nl/portal/nl/kb/adviesbox-online",
                "_blank"
              );
            }
          }
          id="menu-optie-ondersteuning"
        >
          Ondersteuning&nbsp;&nbsp;&nbsp;
          <Icon name={"externallink"} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const NotificatiesMenu = (): ReactElement => {
  const notificatiesContext = useContext(NotificatiesContext);

  return (
    <Dropdown
      className="navigation__toggle"
      onClick={
        /* istanbul ignore next */ () => notificatiesContext.forceReload()
      }
    >
      <Dropdown.Toggle
        variant="link"
        data-testid="notificaties-toggle-button"
        id="dropdown-profiel-opties"
      >
        <span>
          <Icon
            name="notifications"
            iconSize="xl"
            className="navigation__icon"
            alt="Notifications"
          />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Notificaties />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const TopNavbar = ({
  vestigingen,
  organisatie,
  dark = false,
}: TopNavbarProps): ReactElement => {
  const { signout, user } = useContext(AuthContext);
  const { vestiging, setVestiging } = useContext(CurrentVestigingContext);
  const userEmail = user && user.profile && user.profile.name;
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [
    showInvalidAdviesboxResultErrorPage,
    setShowInvalidAdviesboxResultErrorPage,
  ] = useState(false);

  const firstVestigingKey = Object.keys(vestigingen)[0];
  const vestigingIdCookie = getCookie(
    `${getOTAP(window.location.hostname)}_vestigingid`
  );
  const settings = useContext(SettingsContext);
  const isISWBeheerder = hasRoleIswBeheerder(user);

  useEffect(() => {
    let vestigingId = "";
    let vestigingNaam = "";

    if (vestiging.id) {
      vestigingId = vestiging.id;
      vestigingNaam = vestigingen[vestiging.id].naam;
    } else if (
      typeof vestigingIdCookie === "string" &&
      vestigingen.hasOwnProperty(vestigingIdCookie)
    ) {
      vestigingId = vestigingIdCookie;
      vestigingNaam = vestigingen[vestigingIdCookie].naam;
    } else {
      vestigingId = firstVestigingKey;
      vestigingNaam = vestigingen[firstVestigingKey].naam;
    }

    if (vestiging.id === vestigingId) {
      return;
    }

    setVestiging(vestigingId, vestigingNaam);

    setCookie(
      `${getOTAP(window.location.hostname)}_vestigingid`,
      vestigingId,
      isLocal()
        ? window.location.hostname
        : /* istanbul ignore next */ "adviesbox.nl"
    );
  }, [
    firstVestigingKey,
    setVestiging,
    vestiging.id,
    vestigingIdCookie,
    vestigingen,
  ]);

  /* istanbul ignore next */
  const setCurrentVestiging = (
    key: string,
    name: string
  ): (() => void) => (): void => {
    setCookie(
      `${getOTAP(window.location.hostname)}_vestigingid`,
      key,
      isLocal()
        ? window.location.hostname
        : /* istanbul ignore next */ "adviesbox.nl"
    );
    setVestiging(key, name);
  };

  if (showInvalidAdviesboxResultErrorPage) {
    return <ErrorPage error={Error("Invalid Adviesbox Result")} />;
  }

  return (
    <div className="navigation">
      <div className="navigation__content">
        <div className="navigation__left">
          <img
            className="navigation__image"
            data-testid="navigation_logo"
            src="/images/icons/new.svg"
            alt="navigation_logo"
          />
        </div>
        <div className="navigation__right">
          <SupportMenu
            showMeldingMaken={() => setShowMeldingMaken(true)}
            showRemoteAssistance={
              /* istanbul ignore next */ () => setShowRemoteAssistance(true)
            }
          />
          <NotificatiesMenu />
          <Dropdown className="navigation__toggle">
            <Dropdown.Toggle
              variant="link"
              data-testid="dropdown-basic"
              id="dropdown-profiel-opties"
            >
              <span>
                <Icon
                  name="menumedewerkers"
                  iconSize="xl"
                  className="navigation__icon"
                  alt="AppDrawer"
                />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="navigation__menu">
              <Dropdown.Item>
                <span className="navigation__email">{userEmail}</span>
                <div>{vestiging.naam}</div>
                <div>{organisatie.naam}</div>
              </Dropdown.Item>

              <Dropdown.Item
                id="menu-item-wachtwoord-wijzigen"
                data-testid="menu-item-wachtwoord-wijzigen"
                onClick={() => {
                  window.open(
                    `${settings.oauthAuthority}/account/forgotpassword`
                  );
                }}
              >
                Wachtwoord wijzigen
              </Dropdown.Item>
              <Dropdown.Item
                id="menu-item-tweede-factor-wijzigen"
                data-testid="menu-item-tweede-factor-wijzigen"
                onClick={() => {
                  window.open(`${settings.oauthAuthority}/account/change2fa`);
                }}
              >
                Tweede factor wijzigen
              </Dropdown.Item>
              {isISWBeheerder && (
                <Dropdown.Item
                  id="menu-item-backup-codes-genereren"
                  data-testid="menu-item-backup-codes-genereren"
                  onClick={() => {
                    window.open(
                      `${settings.oauthAuthority}/manage/sendrecoverycodes`
                    );
                  }}
                >
                  Back-up codes genereren
                </Dropdown.Item>
              )}

              {Object.keys(vestigingen).length > 1 && (
                <div>
                  <DropdownButton
                    data-testid="dropdown-vestigingen"
                    drop={"left"}
                    variant={""}
                    title={"Vestiging wijzigen"}
                    id={`dropdown-button-drop-left`}
                    key={"left"}
                  >
                    {Object.keys(vestigingen).map((key) => {
                      const naam = vestigingen[key].naam;
                      return (
                        <Dropdown.Item
                          key={key}
                          onClick={setCurrentVestiging(key, naam)}
                        >
                          {naam}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </div>
              )}
              <Dropdown.Item onClick={signout} id="menu-item-uitloggen">
                Uitloggen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/* istanbul ignore next */ showMeldingmaken && user && (
          <SupportTicketModalAjax
            adviesdossierId={null}
            vestigingId={vestiging.id}
            user={user}
            closeModal={() => setShowMeldingMaken(false)}
            showInvalidResultErrorPage={() =>
              setShowInvalidAdviesboxResultErrorPage(true)
            }
          />
        )}
        {/* istanbul ignore next */ showRemoteAssistance && (
          <SupportRemoteAssistanceModal
            closeModal={() => setShowRemoteAssistance(false)}
          />
        )}
      </div>
    </div>
  );
};

TopNavbar.displayName = "TopNavbar";

export default TopNavbar;
